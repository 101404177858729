import React from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Collapsible from "react-collapsible";
import CmsBlock from "app/layout/cmsBlock";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";
import { useMediaQuery } from "react-responsive";
import SeoHelmet from "app/layout/seoHelmet";

const PrivacyPolicy = (props) => {
  let privacyData = props.data.getPrivacyPolicy;
  const [, getSiteCode] = useSite();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <SeoHelmet title="Privacy Policy" url={`/privacy-policy`} />
      <div className="privacy-policy-image-wrapper">
        <CmsBlock id="privacy_policy_image" dangHtml={true} />
      </div>
      <MDBContainer className="privacy-policy-wrapper">
        {!isMobileOrTablet && (
          <MDBRow fluid className="breadcrumbs-container">
            <Breadcrumbs />
          </MDBRow>
        )}
        <h1 className="wow slideInUp">Privacy Policy</h1>

        <hr />
        <MDBContainer className="ilex-and-norwell-logo-wrapper">
          <Icon icon={"dark_logo_norwell"} />
          <Icon icon={"dark_logo_ilex"} />
        </MDBContainer>

        <hr />
        <MDBContainer className="privacy-policy-text-wrapper">
          <CmsBlock id="privacy_policy_text" dangHtml={true} />
        </MDBContainer>

        <div className="collapsible-wrapper">
          {privacyData.map((item) => (
            <>
              <Collapsible
                triggerWhenOpen={
                  <div className="title-and-icon-wrapper">
                    {getSiteCode() === "norwell_default" ? (
                      <Icon icon={"arrow_up_orange_full"} />
                    ) : (
                      <Icon icon={"arrow_up_red_full"} />
                    )}
                    <h5>{` ${item.title}`}</h5>
                  </div>
                }
                trigger={
                  <div className="title-and-icon-wrapper">
                    <Icon icon="arrow_down_full" />
                    <h5>{` ${item.title}`}</h5>
                  </div>
                }
              >
                {item.content.includes("customerservice@norwellinc.com") ? (
                  <>
                    <p>
                      {item.content.replace(
                        "customerservice@norwellinc.com",
                        ""
                      )}
                      <a href="mailto:customerservice@norwellinc.com">
                        {"customerservice@norwellinc.com"}
                      </a>
                    </p>
                  </>
                ) : (
                  <p>{item.content}</p>
                )}
              </Collapsible>
              <hr />
            </>
          ))}
        </div>
      </MDBContainer>
    </>
  );
};
export default PrivacyPolicy;
