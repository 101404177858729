import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import Icon from "app/assets/icon/icon";

const FooterRightColumn = memo((props) => {
  const site = props.site.siteCode;
  return (
    <MDBCol
      lg="6"
      className={typeof site !== "undefined" && site + " footer_right_column"}
    >
      <MDBRow lg="12">
        <MDBCol lg={"5"} className="company-container">
          <h3 className="company-title">Company</h3>
          <CmsBlock id="footer_block_2" dangHtml={true} />
        </MDBCol>
        <MDBCol lg={"7"} className="contact-container">
          <h3>Contact</h3>
          <div className="contact-container-info">
            <div className="icons-wrapper">
              <Icon
                icon="address_icon_orange"
                className={typeof site !== "undefined" && site}
              />
              <Icon
                icon="phone_icon_orange"
                className={typeof site !== "undefined" && site}
              />
              <Icon
                icon="info_icon_orange"
                className={typeof site !== "undefined" && site}
              />
            </div>
            <CmsBlock id="footer_block_3" dangHtml={true} />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
});

export default FooterRightColumn;
