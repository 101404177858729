import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WtbFar = (props) => {
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  return (
    <Link
      onClick={() => {
        props.setOpen(!props.open);
      }}
      to={site.siteCode.includes("norwell") ? "/where-to-buy" : "/find-a-rep"}
    >
      {site.siteCode.includes("norwell") ? "Where to Buy" : "Find a Rep"}
    </Link>
  );
};

export default WtbFar;
