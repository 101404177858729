import React, { useState } from "react";
import ContactForm from "./contactForm";

const ContactFormStateContainer = (props) => {
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const options = [
    { label: "Norwell", value: "norwell" },
    { label: "Ilex", value: "ilex" },
  ];
  const [selectedCompanyValidation, setSelectedCompanyValidation] = useState(
    true
  );
  const [messageValidation, setMessageValidation] = useState(true);

  return (
    <ContactForm
      {...props}
      selectedCompany={selectedCompany}
      setSelectedCompany={setSelectedCompany}
      companyName={companyName}
      setCompanyName={setCompanyName}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      subject={subject}
      setSubject={setSubject}
      message={message}
      setMessage={setMessage}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      options={options}
      selectedCompanyValidation={selectedCompanyValidation}
      setSelectedCompanyValidation={setSelectedCompanyValidation}
      messageValidation={messageValidation}
      setMessageValidation={setMessageValidation}
    />
  );
};

export default ContactFormStateContainer;
