import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import MobileGalleryToolbar from "./mobileToolbar";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

const CustomToolbar = (props) => {
  let { showData, setShowData, norwellData, ilexData, location } = props;
  const options = [
    { index: "norwell-data", label: "Norwell Lighting" },
    { index: "ilex-data", label: "ILEX Custom Metalcraft" },
  ];
  const activeData = isEqual(showData, norwellData)
    ? "norwell-data"
    : "ilex-data";

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  return (
    <MDBRow className="custom-toolbar wow fadeInDown">
      {isMobileOrTablet ? (
        <MobileGalleryToolbar
          options={options}
          activeData={activeData}
          showData={showData}
          setShowData={setShowData}
          norwellData={norwellData}
          ilexData={ilexData}
          location={location}
        />
      ) : (
        <MDBCol size="7" className="toolbar-row">
          <MDBRow>
            <MDBCol size="6" className="toolbar-item">
              <Link
                to={{
                  pathname: "/" + location,
                  state: {
                    currentPage: 1,
                    currentPosts: norwellData,
                  },
                }}
              >
                <h6
                  className={isEqual(showData, norwellData) && "active-tab"}
                  onClick={() => setShowData(norwellData)}
                >
                  Norwell Lighting
                </h6>
              </Link>
            </MDBCol>
            <MDBCol size="6" className="toolbar-item">
              <Link
                to={{
                  pathname: "/" + location,
                  state: {
                    currentPage: 1,
                    currentPosts: ilexData,
                  },
                }}
              >
                <h6
                  className={isEqual(showData, ilexData) && "active-tab"}
                  onClick={() => setShowData(ilexData)}
                >
                  ILEX Custom Metalcraft
                </h6>
              </Link>
            </MDBCol>
            <div className={"underbar " + activeData}></div>
          </MDBRow>
        </MDBCol>
      )}
    </MDBRow>
  );
};

export default CustomToolbar;
