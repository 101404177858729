import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Menu from "../menu";
import Logo from "app/layout/logo";
import Icon from "app/assets/icon/icon";
import MobileLinks from "./mobileLinks";
import WtbFar from "app/layout/wtbFar";
import SiteSwitcher from "app/layout/siteSwitcher";
import InvQuo from "app/layout/InvQuo";
import AccountDropdownMobile from "app/layout/accountDropdown/accountMobile";
import LoginForm from "app/pages/customer/login";
import RegisterForm from "app/pages/customer/register";
import ResetPasswordForm from "app/pages/customer/resetPassword/resetPasswordForm";
import Search from "../../search/search";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Button from "core/components/button";

const MobileHeader = ({
  open,
  setOpen,
  menuItems,
  toggleLogin,
  setToggleLogin,
  toggleRegister,
  setToggleRegister,
  toggleResetPassword,
  setToggleResetPassword,
  displaySearch,
  setDisplaySearch,
  searchVal,
  setSearchVal,
}) => {
  const customer = useSelector((state) => state?.customer);
  const dispatch = useDispatch();
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  const token =
    customer && customer.data && customer.data.token && customer.data.token;
  const [toggleAccountMobile, setToggleAccountMobile] = useState(false);
  useEffect(() => {
    toggleAccountMobile && setToggleAccountMobile(!toggleAccountMobile);
  }, [token]);

  return (
    <div id="mobile-wrapper-id " className={"mobile-wrapper " + open}>
      <div
        id="mobile-header-top-container-id"
        className="mobile-header-top-container"
      >
        <div
          className="mobile-header-left-container"
          onClick={() => {
            const mobileWrapper = document.getElementById("mobile-wrapper-id");
            const mobileHeaderTop = document.getElementById(
              "mobile-header-top-container-id"
            );
            setOpen(!open);
            if (!open) {
              if (mobileWrapper) {
                mobileWrapper.style.width = "95%";
                mobileHeaderTop.style.backgroundColor = "#2e323b";
              }
            } else {
              if (mobileWrapper) {
                mobileWrapper.style.width = "100%";
                mobileHeaderTop.style.backgroundColor = "#363b45";
              }
            }
          }}
        >
          <div id="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {!open ? (
          <>
            <div className="mobile-header-middle-container">
              <Logo />
            </div>
            <div
              className="mobile-header-right-container"
              onClick={() => {
                setDisplaySearch(!displaySearch);
              }}
            >
              <Icon icon="search" />
            </div>
          </>
        ) : (
          <SiteSwitcher />
        )}
      </div>
      <div className={"mobile-search " + displaySearch}>
        {displaySearch && (
          <Search
            open={displaySearch}
            setOpen={setDisplaySearch}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
          />
        )}
      </div>
      <div className={"mobile-menu " + open}>
        {toggleLogin ? (
          <LoginForm
            toggleModal={toggleLogin}
            setToggleModal={setToggleLogin}
            toggleResetPassword={toggleResetPassword}
            setToggleResetPassword={setToggleResetPassword}
            setToggleRegister={setToggleRegister}
          />
        ) : toggleResetPassword ? (
          <ResetPasswordForm
            toggleLoginModal={toggleLogin}
            setToggleLoginModal={setToggleLogin}
            toggleModal={toggleResetPassword}
            setToggleModal={setToggleResetPassword}
          />
        ) : toggleRegister ? (
          <RegisterForm />
        ) : toggleAccountMobile ? (
          <AccountDropdownMobile
            toggleAccountMobile={toggleAccountMobile}
            setToggleAccountMobile={setToggleAccountMobile}
            open={open}
            setOpen={setOpen}
          />
        ) : !toggleResetPassword &&
          !toggleLogin &&
          !toggleRegister &&
          !toggleAccountMobile ? (
          <>
            <div className="mobile-menu-container">
              <Menu menuItems={menuItems} open={open} setOpen={setOpen} />
              <MobileLinks setOpen={setOpen} site={site} />
            </div>
            <div className={"mobile-header-bottom-container " + open}>
              <div className="mobile-header-bottom-wtbFar">
                <WtbFar setOpen={setOpen} open={open} />
              </div>
              {customer?.data?.token ? (
                <div className="mobile-header-bottom-invQuo">
                  <InvQuo setOpen={setOpen} open={open} />
                </div>
              ) : (
                <></>
              )}
              <div className="mobile-header-bottom-right-container">
                <div
                  className="mobile-header-bottom-fav"
                  onClick={() => !token && setToggleLogin(true)}
                >
                  <Link
                    to={token ? "favorites" : ""}
                    onClick={() => token && open && setOpen(false)}
                  >
                    <Icon icon="favorites" />
                  </Link>
                </div>
                <div
                  className={`mobile-header-bottom-accountDropdown ${
                    token && " logged-in"
                  }`}
                >
                  <Icon
                    icon="account_icon"
                    onClick={() =>
                      token
                        ? setToggleAccountMobile(true)
                        : setToggleLogin(true)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {toggleResetPassword ||
        toggleLogin ||
        toggleRegister ||
        toggleAccountMobile ? (
          <div className={"mobile-header-bottom-container--active " + open}>
            {token && (
              <Button
                className="logout-customer-btn-acc-mobile"
                onClick={() => {
                  dispatch({
                    type: "jola/customer/LOGOUT_CUSTOMER",
                  });
                  open && setOpen(!open);
                }}
              >
                Logout
              </Button>
            )}
            <div
              className="mobile-header-bottom-back-container"
              onClick={() => {
                if (toggleResetPassword) {
                  setToggleResetPassword(false);
                  setToggleRegister(false);
                  setToggleLogin(true);
                  setToggleAccountMobile(false);
                } else {
                  setToggleLogin(false);
                  setToggleRegister(false);
                  setToggleResetPassword(false);
                  setToggleAccountMobile(false);
                }
              }}
            >
              <Icon icon="mobile_white_arrow" />
              <label className="mobile-header-back-label">
                {toggleResetPassword ? "Back to Login" : "Back"}
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MobileHeader;
