import React from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBContainer, MDBRow, MDBView, MDBMask } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import HeritageOfQuality from "app/pages/home/heritageOfQuality";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import { useSelector } from "react-redux";
import IlexBlocks from "./ilexBlocks/ilexBlocks";
import NorwellBlocks from "./norwellBlocks";
import useSite from "app/state/hooks/siteHooks/useSite";
import { useMediaQuery } from "react-responsive";
import SeoHelmet from "app/layout/seoHelmet";

const About = () => {
  const [, getSiteCode] = useSite();
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <SeoHelmet title="About Us" url={`/about-us`} />
      <div className="about-us-hero-image-and-text-wrapper">
        <MDBView className="banner-image-view">
          <CmsBlock id="about_us_banner_block" dangHtml={true} />
          <MDBMask>
            <div className="about-us-text-wrapper wow fadeInDown">
              <p>
                <label className="rhomboid"></label>
                <label className="text-years">YEARS OF EXPERIENCE</label>
              </p>
              <CmsBlock id="about_us_banner_image_text" dangHtml={true} />
            </div>
          </MDBMask>
        </MDBView>
      </div>

      <MDBContainer className="about-us-page">
        {!isMobileOrTablet && (
          <MDBRow fluid className="breadcrumbs-container">
            <Breadcrumbs />
          </MDBRow>
        )}

        {site.siteCode.includes("norwell") ? (
          <>
            <NorwellBlocks />
            <IlexBlocks />
          </>
        ) : (
          <>
            <IlexBlocks />
            <NorwellBlocks />
          </>
        )}
      </MDBContainer>
      <div className="heritage-mobile-about-us">
        <HeritageOfQuality />
      </div>
      <div className="about-us-stay-in-touch-wrapper">
        <MDBView className="stay-in-touch-view">
          <CmsBlock id="about_us_stay_in_touch" dangHtml={true} />
          <MDBMask className={getSiteCode()}>
            <div className="about-us-stay-in-touch-text-wrapper wow fadeInDown">
              <CmsBlock id="about_us_stay_in_touch_text" dangHtml={true} />
              <Button to={"/contact-us"} className="with-arrow">
                <Icon icon="small_black_arrow_left" />
                Contact Us
              </Button>
            </div>
          </MDBMask>
        </MDBView>
      </div>
    </>
  );
};

export default About;
