import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useWebsite } from "app/hooks/useWebsite";
import sites from "config/sites";
import Icon from "app/assets/icon/icon";

const SiteSwitcher = memo(({ switcherLocation }) => {
  const site = useSelector((state) => state.site);
  const websiteHook = useWebsite();
  const active = site.siteCode.includes("norwell") ? "norwell" : "ilex";

  switch (switcherLocation) {
    case "footer": {
      return (
        <div className="site-switcher-container">
          <a
            onClick={(e) => e.preventDefault()}
            className={active === "norwell" ? "norwell_logo" : "ilex_logo"}
          >
            <Icon
              icon={
                switcherLocation === "footer" && active === "norwell"
                  ? "switcher_norwell_logo"
                  : "switcher_ilex_logo"
              }
            />
          </a>
          <a
            onClick={() => {
              websiteHook.useWebsite(
                active === "norwell"
                  ? sites.ilex.siteCode
                  : sites.norwell.siteCode
              );
            }}
            className={active === "norwell" ? "ilex_logo" : "norwell_logo"}
          >
            <Icon
              icon={
                switcherLocation === "footer" && active === "norwell"
                  ? "switcher_ilex_logo"
                  : "switcher_norwell_logo"
              }
            />
          </a>
        </div>
      );
    }
    case "header":
    case undefined:
    default: {
      return (
        <div className="site-switcher-container">
          <a
            className={active === "norwell" ? "active " : null}
            onClick={(e) => {
              if (active === "norwell") {
                e.preventDefault();
              } else {
                websiteHook.useWebsite(sites.norwell.siteCode);
              }
            }}
          >
            <Icon icon={"switcher_norwell_logo"} />
          </a>
          <a
            className={active === "ilex" ? "active" : null}
            onClick={(e) => {
              if (active === "ilex") {
                e.preventDefault();
              } else {
                websiteHook.useWebsite(sites.ilex.siteCode);
              }
            }}
          >
            <Icon icon={"switcher_ilex_logo"} />
          </a>
        </div>
      );
    }
  }
});

export default SiteSwitcher;
