import React, { useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { useSelector } from "react-redux";

const NorwellBlocks = () => {
  const [readMore, setReadMore] = useState(false);
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );

  return (
    <>
      <div className="norwell-blocks-wrapper">
        <CmsBlock id="norwell_title_about_us" dangHtml="true" />
        <MDBRow className="first-block-about-us">
          <MDBCol lg="9">
            <MDBRow>
              <MDBCol lg="6">
                <CmsBlock
                  id="about_us_first_block_first_column"
                  dangHtml={true}
                />
              </MDBCol>
              <MDBCol lg="6" className={"norwell-text " + readMore}>
                <CmsBlock
                  id="about_us_first_block_second_column"
                  dangHtml={true}
                />
                <p className="read-more" onClick={() => setReadMore(!readMore)}>
                  Read {readMore ? "less" : "more"}
                </p>
              </MDBCol>

              {site.siteCode.includes("norwell") ? (
                <></>
              ) : (
                <>
                  <div className="about-us-visit-website">
                    <Icon icon="right_arrow_white" />
                    <CmsBlock
                      dangHtml={true}
                      id="about_us_visit_norwell_website"
                    />
                  </div>
                </>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol lg="3">
            <CmsBlock id="about_us_first_block_third_column" dangHtml={true} />
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default NorwellBlocks;
